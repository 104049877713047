import './App.css'
import { Layout, Spin } from 'antd'
import ContentComponent from './components/ContentComponent'
import FooterComponent from './components/FooterComponent'
import LoginComponent from './components/LoginComponent'
import { useEffect, useState } from 'react'
import axios from './axios.js';


function App() {

  const [loggedIn, setLoggedIn] = useState(false)
  const [loading, setLoading] = useState(true)
  const [token, setToken] = useState(null)


  useEffect(() => {
    axios.post('/api/auth/login')
      .then((res) => {
        setLoggedIn(true);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);


  const handleAuth = (status) => {
    console.log('handleAuth', status);
    setLoggedIn(status)
  }

  const handleToken = (token) => {
    setToken(token)
  }


  return (
    <Layout>
      {loading ? (
        <div className='spin-wrapper'>
          <Spin size="large" />
        </div>
      ) : (<>
        {loggedIn ? (
          <>
            <ContentComponent onLogout={handleAuth} token={token} />
            <FooterComponent />
          </>
        ) : (
          <LoginComponent onLogin={handleAuth} token={(handleToken)} />
        )}
      </>)}



    </Layout>
  )
}

export default App
