import { Modal, Form, Input, Button, message } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import axios from './../axios.js';
import { useState } from 'react';



export default function LoginComponent({ onLogin, token }) {
	const [loading, setLoading] = useState(false);

	const handleAuthorization = (values) => {
		setLoading(true);
		axios
			.post('/api/auth/login', values)
			.then((res) => {
				// console.log(res);
				if (res.status === 200) {
					setLoading(false);
					message.success('Zalogowano pomyślnie');
					localStorage.setItem('auth', res.data.token);
					token = res.data.token;
					onLogin(true);
				}
			})
			.catch((err) => {
				if (err.response.status === 403) {
					message.error(err.response.data.message);
					setLoading(false);
				} else console.log(err);
			});
	};

	return (
		<div>
			<Modal title="Logowanie" width={350} open={true} footer={null} closable={false}>
				<Form name="normal_login" className="login-form" initialValues={{ remember: true }} onFinish={handleAuthorization}>
					<Form.Item name="username" rules={[{ required: true, message: 'Login jest wymagany' }]}>
						<Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" size="large" />
					</Form.Item>
					<Form.Item name="password" rules={[{ required: true, message: 'Hasło jest wymagane' }]}>
						<Input prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="Password" size="large" />
					</Form.Item>

					<Form.Item>
						<Button type="primary" htmlType="submit" size="large" loading={loading} className="login-form-button">
							Zaloguj się
						</Button>
					</Form.Item>
				</Form>
			</Modal>
		</div>
	);
}
